<template>
    <div class="">
        <s-form @submit="addItem">
            <h2 class="head font-poppins-semibold fs-lg-4 mt-3  text-primary">Fodder Production</h2>
            <div class="row">
                <div class="col-lg-2 mt-4 ">
                    <!--            col-lg-2 pt-4 column w-15p pr-0-->
                    <!--            <div class="form-group ">-->

                    <validated-date-picker format="DD-MM-YYYY" class="c-input-datepicker text-black field-required"
                                           v-model="model.production_date" label="Date"
                                           :rules="rules.common"></validated-date-picker>
                    <!--            </div>-->
                </div>
            </div>

            <div class="col-lg-12 pt-4   pl-0">
                <div class="row">
                    <div class="form-group col-lg-2">
                        <validated-input class="pb-0 mb-xl-n4 text-black" v-model="model.rainfall"
                                         label="Rainfall in mm" :rules="rules.positiveOnly"
                                         type="number"></validated-input>
                    </div>
                    <div class="form-group col-lg-2">
                        <validated-input class="pb-0 mb-xl-n4 text-black" v-model="model.mean_min_temp"
                                         label="Mean Min.Temp" :rules="rules.positiveOnly"
                                         type="number"></validated-input>
                    </div>
                    <div class="form-group col-lg-2">
                        <validated-input class="pb-0 mb-xl-n4 text-black" v-model="model.mean_max_temp"
                                         label="Mean Max.Temp" :rules="rules.positiveOnly"
                                         type="number"></validated-input>
                    </div>
                    <div class="form-group col-lg-2">
                        <validated-input class="pb-0 mb-xl-n4 text-black" v-model="model.humidity" label="Humidity in %"
                                         :rules="rules.positiveOnly" type="number"></validated-input>
                    </div>
                    <div class="form-group col-lg-2">
                        <validated-input class="pb-0 mb-xl-n4 text-black" v-model="model.sunshine"
                                         label="Sunshine Recorded" :rules="rules.positiveOnly"
                                         type="number"></validated-input>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 pt-4  pl-0">
                <div class="row">
                    <div class="form-group col-lg-2">
                        <validated-vue-select label="Crop/Variety" :options="cropOptions" v-model="model.crop_variety"
                                              class="c-input-select text-black field-required"
                                              :rules="{required:true}"></validated-vue-select>
                    </div>
                    <div class="form-group col-lg-2">
                        <validated-input label="Source" v-model="model.source" class="text-black"></validated-input>
                    </div>
                    <div class="form-group col-lg-2">
                        <validated-input label="Number" v-model="model.number" class=" text-black"
                                         :rules="rules.positiveOnly" type="number"></validated-input>
                    </div>
                    <div class="form-group col-lg-2">
                        <validated-input class="pb-0 mb-xl-n4 text-black field-required" v-model="model.total_area"
                                         :rules="rules.area" label="Total Area" type="number"></validated-input>
                    </div>
                    <div class="form-group col-lg-2">
                        <validated-input class="pb-0 mb-xl-n4 text-black field-required" v-model="model.area_harvested"
                                         :rules="rules.areaHarvestedarea" label="Area Harvested"
                                         type="number"></validated-input>
                    </div>
                    <div class="form-group col-lg-2">
                        <validated-input class="pb-0 mb-xl-n4 text-black" v-model="model.green"
                                         label="Green"></validated-input>
                    </div>
                </div>
            </div>
            <div class="row ">
                <div class="form-group col-lg-2">
                    <validated-input class="pb-0 mb-xl-n4 text-black" v-model="model.dm" label="DM"></validated-input>
                </div>
                <div class="form-group col-lg-2">
                    <validated-input class="pb-0 mb-xl-n4 text-black" v-model="model.remarks"
                                     label="Remarks"></validated-input>
                </div>
            </div>
            <div class="column fl-x-br pt-7">
                <div class="btn-group">
                    <btn text="Save" :loading="loading" loading-text="Saving..." class="px-4"></btn>
                    <btn text="Cancel" type="reset" @click="BackToHome" design="basic-b" class="px-4"></btn>
                </div>
            </div>
        </s-form>
    </div>
</template>

<script>
import urls from '../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'FodderProduction',
    data () {
        return {
            loading: false,
            URL: urls.fodder_production.addEdit,
            model: {
                production_date: '',
                rainfall: '',
                mean_min_temp: '',
                mean_max_temp: '',
                humidity: '',
                sunshine: '',
                crop_variety: '',
                source: '',
                number: '',
                total_area: '',
                area_harvested: '',
                green: '',
                dm: '',
                remarks: ''
            },
            cropOptions: [
                { label: 'Crop', value: 'Crop' },
                { label: 'Variety', value: 'Variety' },
                { label: 'Type', value: 'Type' }
            ],
            rules: {
                common: {
                    required: true,
                    customValidator: (value) => {
                        return this.validationDate(value);
                    }
                },
                positiveOnly: {
                    required: false,
                    customValidator: (value) => {
                        return this.avoidNegetive(value);
                    }
                },
                area: {
                    required: true,
                    customValidator: () => {
                        return this.areaValidation();
                    }
                },
                areaHarvestedarea: {
                    required: true,
                    customValidator: () => {
                        return this.areaValidation();
                    }
                }
            }

        };
    },
    methods: {
        BackToHome () {
            this.$router.push({ path: '/fodder/' });
        },
        avoidNegetive (no) {
            const num = no;
            if (num < 0) {
                return 'Please enter a valid number';
            }

            return true;
        },
        areaValidation () {
            /* const num = no; */
            const totalArea = parseInt(this.model.total_area);
            const harvested = parseInt(this.model.area_harvested);
            if (this.model.total_area === '') {
                return 'Please enter Total area';
            }

            if (harvested < 0) {
                return 'Please enter a valid number';
            }
            if (totalArea < harvested) {
                return 'Area harvested  must be less than Total area';
            }

            return true;
        },
        validationDate () {
            const checkDate = this.model.production_date;

            const checkDateParts = checkDate.split('-');
            if (checkDateParts.length !== 3) {
                return 'Please enter a valid Date in the format DD-MM-YYYY.';
            }
            const checkDateDay = parseInt(checkDateParts[0], 10);
            const checkDateMonth = parseInt(checkDateParts[1], 10);
            const checkDateYear = parseInt(checkDateParts[2], 10);

            const checkDateObj = new Date(checkDateYear, checkDateMonth - 1, checkDateDay);

            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            if (checkDateObj > currentDate) {
                return 'Future Date not allowed.';
            }
            return true;
        },
        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(urls.fodder_production.addEdit, this.model);
                const result = response.data;
                if (result.success) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.$router.push({ path: '/fodder/' });
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage = '';
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
